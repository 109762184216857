import React, { useState } from "react";
import "../assets/css/style.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import TypeWriterEffect from 'react-typewriter-effect';
const { Configuration, OpenAIApi } = require("openai");

const Home = () => {
	const temperatures = [
		{
			value: 0.1,
			label: "0.1°C",
		},
		{
			value: 0.2,
			label: "0.2°C",
		},
		{
			value: 0.3,
			label: "0.3°C",
		},
		{
			value: 0.4,
			label: "0.4°C",
		},
		{
			value: 0.5,
			label: "0.5°C",
		},
		{
			value: 0.6,
			label: "0.6°C",
		},
		{
			value: 0.7,
			label: "0.7°C",
		},
		{
			value: 0.8,
			label: "0.8°C",
		},
		{
			value: 0.9,
			label: "0.9°C",
		},
		{
			value: 0.1,
			label: "0.9°C",
		},
	];
	const [loading, setLoading] = useState(false);
	const [response, setResponse] = useState("");
	const [temp, setTemp] = useState(0.5);
	const [length, setLength] = useState(350);
	const updateRange = (e, data) => {
		setTemp(data);
	};
	const updateLength = (e, data) => {
		setLength(data);
	};
	const onFormSubmit = (e) => {
		setLoading(true);
		e.preventDefault();

		const formData = new FormData(e.target);
		const formDataObj = Object.fromEntries(formData.entries());
		// OPEN AI CODE

		const configuration = new Configuration({
			apiKey: "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",
		});
		const openai = new OpenAIApi(configuration);

		openai
			.createCompletion({
				model: "text-davinci-003",
				prompt: `${formDataObj.ProductName}`,
				temperature: temp,
				max_tokens: length,
				top_p: 1,
				frequency_penalty: 0,
				presence_penalty: 0,
			})
			.then((response) => {
				setResponse(response?.data?.choices[0]?.text);
				setLoading(false);
			});
	};
	return (
		<>
			<section className="chatgpt_home">
				<div className="container">
					<div className="chatgpt-form">
						<div className="row">
							<div className="col-md-12">
								<div className="heading_wrapper pb-3">
									<h2>Tell AI, What You Want?</h2>
								</div>
							</div>
							<div className="col-lg-12">
								<form onSubmit={onFormSubmit}>
									<div className="form-group">
										<input
											name="ProductName"
											type="text"
											className="form-control prompt-input"
										/>
									</div>
									<div className="button-group">
										<button className="btn" type="submit">
											Get AI Suggestion
										</button>
									</div>
								</form>
							</div>
							<div className="col-lg-3">
								<label>Temperature</label>
								<div className="temperature_box">
									<Slider
										value={temp}
										onChange={updateRange}
										marks={temperatures}
										step={0.1}
										min={0.1}
										max={1}
										sx={{
											color: "white",
										}}
									/>
									<input
										type="text"
										readonly
										value={temp}
										className="form-control"
									/>
								</div>
							</div>
							<div className="col-lg-3">
								<label>Maximum Lenght</label>
								<div className="lenght-box">
									<Slider
										size="small"
										defaultValue={length}
										value={length}
										onChange={updateLength}
										aria-label="Small"
										valueLabelDisplay="auto"
										min={1}
										max={4000}
										sx={{
											color: "white",
										}}
									/>
								</div>
							</div>
							<div className="col-lg-12">
								<div className="response_box">
									<div className="title">
										<h2>The Response from the AI will be show here</h2>
									</div>
									<div className="response-text">
										{loading ? (
											<>Please wait your response is generating...</>
										) : (
											<>
												<TypeWriterEffect
													textStyle={{
														color: '#fff',
														fontSize: '16px',
													}}
													startDelay={2000}
													cursorColor="#3F3D56"
													text={response}
													typeSpeed={30}
												/>
											</>
										)}

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Home;
